.atm_l8_8b6l8m.atm_l8_8b6l8m{padding:4rem 3rem;}
.atm_1ilet9j_11p5wf0.atm_1ilet9j_11p5wf0 div{display:grid;}
.atm_wchyn_gktfv.atm_wchyn_gktfv div{gap:20px;}
.atm_1w3jj94_kbqlyw.atm_1w3jj94_kbqlyw div{max-width:450px;}
@media (max-width:730px){.atm_m3rkw0_ov6kre.atm_m3rkw0_ov6kre.atm_m3rkw0_ov6kre{padding:3rem 3rem;}}
@keyframes fadeInAnimation {
    0% {
      opacity: 0;
      transform: translateY(15%);
      filter: blur(5px);
    }
    100% {
      opacity: 1;
      filter: blur(0);
      transform: translateY(0);
    }
  }
.atm_e2_1wugsn5{height:auto;}
.atm_vy_1mjvjzg{width:300px;}
.atm_k4_idpfg4{opacity:0;}
.atm_p_oppgaz{animation:fadeInAnimation ease 0.5s;}
.atm_16_kb7nvz.atm_16_kb7nvz{animation-iteration-count:1;}
.atm_12_q7pw6w.atm_12_q7pw6w{animation-fill-mode:forwards;}
@media (max-width:730px){.atm_1g7s18s_cs5v99.atm_1g7s18s_cs5v99.atm_1g7s18s_cs5v99{grid-row-start:2;}}
