.pn36p5c{border:1px #FFF solid;background-color:#FFF;width:250px;}
.h999xi6{padding-bottom:32pt;}
.h1rgd5eu{padding-bottom:24pt;}
.hlhi7mo{padding-bottom:20pt;}
.h9fgbbo{padding-bottom:16pt;}
.t10w4lpt{padding-bottom:14pt;}
.n1yew98v{width:auto;height:300px;}@media (max-width:500px){.n1yew98v{width:auto;height:300px;}}
.b1o76q86{width:auto;height:450px;}@media (max-width:500px){.b1o76q86{width:auto;height:300px;}}
.gbo3edd{background-color:#2A403A;}
.p1dqygwd{background-color:#D96B62;}
.t1froc0s{font-family:'Source Sans Pro';font-weight:700;font-size:70pt;text-align:center;line-height:9rem;padding:0;margin:0;}@media (max-width:1200px){.t1froc0s{font-size:55pt;line-height:6rem;}}@media (max-width:870px){.t1froc0s{font-size:42pt;line-height:5rem;}}@media (max-width:550px){.t1froc0s{font-size:35pt;line-height:4rem;}}
.h19go0a8{margin:0;font-family:'Source Sans Pro';font-weight:600;font-size:32pt;color:#2A403A;}
.h1scvkto{margin:0;font-family:'Source Sans Pro';font-weight:600;font-size:24pt;color:#2A403A;}
.hpqavvs{margin:0;font-family:'Source Sans Pro';font-weight:600;font-size:20pt;color:#2A403A;}
.h1p9n9fe{margin:0;font-family:'Source Sans Pro';font-weight:400;font-size:16pt;color:#2A403A;}
.brbta3g{font-family:'Source Sans Pro';font-weight:300;margin:0;font-size:14pt;}
.s3iuqbz{font-family:'Source Sans Pro';font-weight:400;margin:0;font-size:14pt;}
.sw9nqp4{font-family:'Source Sans Pro';font-weight:300;margin:0;font-size:10pt;}
.s1633bhj{background-color:#D9D1D0;color:#000;padding-bottom:4rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;justify-items:center;gap:5vw;}.s1633bhj div{max-width:300px;}
.i1vxjxgr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:4vw;padding:15px 0;}
.t1nmperf{padding:64px 80px;text-align:left;color:#000;max-width:850px;}@media (max-width:550px){.t1nmperf{padding:64px 40px;}}
.a1r54gvn{-webkit-text-decoration:none;text-decoration:none;}
.b1c15rr5{color:white;font-size:14pt;-webkit-text-decoration:none;text-decoration:none;padding-bottom:0.2rem;background:linear-gradient(#000,#000) bottom / 0 0.08em no-repeat;-webkit-transition:200ms background-size;transition:200ms background-size;}.b1c15rr5:hover{background-size:100% 0.08em;}
.h1sqmhty{color:white;-webkit-text-decoration:none;text-decoration:none;padding-bottom:0.2rem;background:linear-gradient(#FFF,#FFF) bottom / 0 0.1em no-repeat;-webkit-transition:200ms background-size;transition:200ms background-size;}.h1sqmhty:hover{background-size:100% 0.1em;}
.gsp6d3o{color:#2A403A !important;padding:8pt 8pt !important;font-size:1rem;border-radius:0 !important;background-color:#D9D1D0;-webkit-text-decoration:none;text-decoration:none;background:linear-gradient(#2A403A,#2A403A) bottom / 0 0.13em no-repeat;-webkit-transition:150ms background-size;transition:150ms background-size;}.gsp6d3o:hover{background-size:100% 0.13em;}
.lalr4nw{-webkit-text-decoration:none;text-decoration:none;list-style-type:none;padding:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;line-height:1.5rem;margin:0;font-size:14pt;}.lalr4nw li{padding-bottom:0.2rem;}
.oc84h50{margin:0;padding-left:2rem;margin:0;line-height:1.5rem;}.oc84h50 li{padding-bottom:0.2rem;}
.f1dv1qgb{border:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:10px;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.b9vfphd{cursor:pointer;z-index:1000;padding:10pt 14pt;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;border:none;color:WHITE;gap:0.2rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-align-self:center;-ms-flex-item-align:center;align-self:center;margin:0;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;border-radius:16pt;-webkit-text-decoration:none;text-decoration:none;}.b9vfphd img{width:auto;height:1rem;}
.b1r5856g{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;gap:1.5rem !important;}
.p13n3r0g{background-color:#D96B62;cursor:pointer;color:white;font-size:10pt;border:1px solid #D96B62;}.p13n3r0g:hover{background-color:#d96b6280;}
.i1ybjcxy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin:0;border:none;background:none;padding:6px;-webkit-text-decoration:none;text-decoration:none;border-radius:16px;cursor:pointer;}.i1ybjcxy img{width:auto !important;height:2.5rem !important;}
.f1fgzu4q{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-top:1rem;gap:0.5rem;}
.lrzx1ve{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#D9D1D0;}
